// Third-party libraries
import React, { Component } from "react";

// Project dependencies
import {
  getHeaders,
  errorHandler,
  requestLoanUrl,
  paybackLoanUrl
} from "../apihelpers";

class LoanOffer extends Component {
  requestLoan() {
    const headers = getHeaders();
    fetch(requestLoanUrl, {
      method: "POST",
      headers
    })
      .then(errorHandler)
      .then(() => {
        window.gtag("event", "request", {
          event_category: "loan"
        });
        this.props.afterLoan();
      });
  }

  paybackLoan() {
    const headers = getHeaders();
    fetch(paybackLoanUrl, {
      method: "POST",
      headers
    })
      .then(errorHandler)
      .then(() => {
        window.gtag("event", "pay back", {
          event_category: "loan"
        });
        this.props.afterLoanPayback();
      });
  }

  render() {
    if (
      this.props.player.loan_payback_eligible ||
      this.props.repaidLoanOnThisPage
    ) {
      return (
        <aside className="loan-offer alert alert--full-width">
          {this.props.repaidLoanOnThisPage ? (
            <p>Awesome. Nice work.</p>
          ) : (
            <p>
              You have enough points to pay back loans, plus 10 percent vig.
              Would you like to{" "}
              <button
                type="button"
                className="button--inline"
                onClick={() => this.paybackLoan()}
              >
                settle up
              </button>
              ?
            </p>
          )}
        </aside>
      );
    }

    if (this.props.player.loan_eligible || this.props.tookLoanOnThisPage) {
      return (
        <aside className="loan-offer alert alert alert--full-width">
          <p>
            <strong>
              Uh-oh. It looks like you’re running out of points. Would you like
              to take out a 750-point bailout loan?
            </strong>
          </p>
          <p className="loan-offer__fine-print">
            The fine print: For the first three days after a loan, you are
            limited to 25-point bets, and only one loan may be requested each
            week. Loans are charged 10 percent vigorish.
          </p>
          {this.props.tookLoanOnThisPage ? (
            <p>You’re all set. Good luck!</p>
          ) : (
            <button
              className="loan-offer__button"
              type="button"
              onClick={() => this.requestLoan()}
            >
              Yes, do it
            </button>
          )}
        </aside>
      );
    } else {
      return null;
    }
  }
}

export default LoanOffer;
