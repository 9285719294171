// Third-party libraries
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { scaleLinear, scaleSymlog } from "d3-scale";
import { max } from "d3-array";
import { intComma } from "humanize-plus";
import { select, selectAll } from "d3-selection";
import { axisBottom, axisLeft } from "d3-axis";
import { annotation, annotationCalloutCircle } from "d3-svg-annotation";
import variables from "../styleVariables";

// Project dependencies
import {
  debounce,
  normalizePayout,
  payoutToOdds,
  oddsToString,
} from "../utilities";

class BetHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.betHistory_el = React.createRef();
  }
  componentDidMount() {
    const delayedMakeChart = debounce(() => {
      this.drawChart();
    }, 100);
    window.addEventListener("resize", delayedMakeChart);
    this.drawChart();
  }

  componentDidUpdate(prevProps) {
    // Stringify does a deep comparison of array of objects.
    if (
      JSON.stringify(this.props.losers) !== JSON.stringify(prevProps.losers) ||
      JSON.stringify(this.props.winners) !== JSON.stringify(prevProps.winners)
    ) {
      this.drawChart();
    }
  }

  drawChart() {
    const betHistory_el = this.betHistory_el;
    const outerWidth = betHistory_el.current.getBoundingClientRect().width;
    const outerHeight = betHistory_el.current.getBoundingClientRect().height;
    const betHistory = [...this.props.losers, ...this.props.winners];
    const loserWidth = 6;

    betHistory.map((bet) => {
      bet.pre_payout = parseFloat(bet.pre_payout);
      if (bet.pre_payout < 1.1) {
        bet.pre_payout = 1.1;
      }
      bet.pre_payout = normalizePayout(bet.pre_payout);
      bet.final_payout = normalizePayout(bet.final_payout);
      return bet;
    });

    selectAll("svg.bet-history > *").remove();

    const mobile = window.innerWidth < 600;
    const margin = { top: mobile ? 60 : 30, right: 0, bottom: 30, left: 60 },
      width = outerWidth - margin.left - margin.right,
      height = outerHeight - margin.top - margin.bottom;

    var x = scaleSymlog()
      .domain([1, max(betHistory, (d) => max([d.final_payout, d.pre_payout]))])
      .range([0, width]);

    var y = scaleSymlog()
      .domain([0, max(betHistory, (d) => d.amount)])
      .range([height, 0]);

    var winnerRadius = scaleLinear().domain([10, 500]).range([8, 400]);

    var svg = select("svg.bet-history")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // X axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`) //"translate(0," + height + ")")
      .attr("class", "axis")
      .call(
        axisBottom(x)
          .tickValues(
            [2, 1001, 501, 101, 51, 21, 11, 6, 201, 4, 1.1, 3, 1.5, 2.5]
              .filter((payout) => payout < max(betHistory, (d) => d.pre_payout))
              .slice(0, 8)
          )
          .tickFormat(function (d) {
            return `${payoutToOdds(d)[0]}/${payoutToOdds(d)[1]}`;
          })
      );

    // text label for the y axis
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - height / 2)
      .attr("dy", 14)
      .attr("class", "axis-label")
      .style("text-anchor", "middle")
      .text("Size of bet");

    // text label for the x axis
    svg
      .append("text")
      .attr("transform", "translate(" + width / 2 + " ," + (height + 40) + ")")
      .attr("class", "axis-label")
      .style("text-anchor", "middle")
      .text("Odds");

    // // Y axis
    svg
      .append("g")
      .attr("class", "axis")
      .call(
        axisLeft(y)
          .ticks(10)
          .tickValues(
            [1, 5, 10, 25, 50, 100, 250, 500, 1000, 5000, 10000].filter(
              (amount) => amount < max(betHistory, (d) => d.amount)
            )
          )
      );

    // Draw rectangles showing difference between pre and final, if any
    svg
      .selectAll(".chart__bet")
      .data(betHistory.filter((bet) => bet.pre_payout !== bet.final_payout))
      .enter()
      .append("rect")
      .attr("x", (d) =>
        x(d.pre_payout < d.final_payout ? d.pre_payout : d.final_payout)
      )
      .attr("y", 0)
      .attr("width", (d) => Math.abs(x(d.final_payout) - x(d.pre_payout)))
      .attr("height", height)
      .attr("fill", (d) =>
        d.pre_payout < d.final_payout
          ? variables.colorErrorPanel
          : variables.colorPrimaryPanel
      )
      .style("opacity", 0)
      .attr("class", (d) => "rectangle final-payout-" + d.id);

    // Final odds
    svg
      .selectAll(".chart__bet")
      .data(betHistory)
      .enter()
      .append("line")
      .attr("class", "")
      .attr("x1", (d) => x(d.final_payout))
      .attr("x2", (d) => x(d.final_payout))
      .attr("y1", 0)
      .attr("y2", height)
      .style("stroke-width", 1)
      .style("opacity", 0)
      .attr(
        "class",
        (d) => `line line--dotted line--neutral ${"final-payout-" + d.id}`
      );

    // losers
    // NW to SE slash
    svg
      .selectAll(".chart__bet")
      .data(betHistory.filter((bet) => bet.status === 4))
      .enter()
      .append("line")
      .attr("x1", (d) => x(d.pre_payout) - loserWidth / 2)
      .attr("y1", (d) => y(d.amount) - loserWidth / 2)
      .attr("x2", (d) => x(d.pre_payout) + loserWidth / 2)
      .attr("y2", (d) => y(d.amount) + loserWidth / 2)
      .style("stroke-width", 1)
      .style("stroke", variables.colorCancel)
      .style("opacity", 0.6);

    // NE to SW slash
    svg
      .selectAll(".chart__bet")
      .data(betHistory.filter((bet) => bet.status === 4))
      .enter()
      .append("line")
      .attr("x1", (d) => x(d.pre_payout) + loserWidth / 2)
      .attr("y1", (d) => y(d.amount) - loserWidth / 2)
      .attr("x2", (d) => x(d.pre_payout) - loserWidth / 2)
      .attr("y2", (d) => y(d.amount) + loserWidth / 2)
      .style("stroke-width", 1)
      .style("stroke", variables.colorCancel)
      .style("opacity", 0.6);

    // Loser overlay
    svg
      .selectAll(".chart__bet")
      .data(betHistory.filter((bet) => bet.status === 4))
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.pre_payout) - loserWidth)
      .attr("y", (d) => y(d.amount) - loserWidth)
      .attr("width", loserWidth * 2)
      .attr("height", loserWidth * 2)
      .style("fill", "transparent")
      .on("mouseover", function (d) {
        select(this).transition().duration(200).style("opacity", 0.9);

        selectAll(".final-payout-" + d.id).style("opacity", 1);

        if (d.pre_payout === d.final_payout) {
          selectAll(".final-odds-legend--same").style("opacity", 1);
        }

        if (d.pre_payout < d.final_payout) {
          selectAll(".final-odds-legend--over").style("opacity", 1);
        }

        if (d.pre_payout > d.final_payout) {
          selectAll(".final-odds-legend--under").style("opacity", 1);
        }

        select(".bet-history__tooltip")
          .text(
            `${intComma(d.amount)} on ${d.selection.name} at 
            ${oddsToString(d.odds)} in ${d.proposition.name}`
          )
          .classed("hide", false)
          .classed("bet-history__tooltip--loser", true);
      })
      .on("mouseout", function (d) {
        select(this).transition().duration(300).style("opacity", 0.6);

        selectAll(".final-payout-" + d.id).style("opacity", 0);
        selectAll(".final-odds-legend").style("opacity", 0);
        select(".bet-history__tooltip")
          .text("")
          .classed("hide", true)
          .classed("bet-history__tooltip--loser", false);
      });

    // winners
    svg
      .selectAll(".chart__bet")
      .data(
        betHistory
          .filter((bet) => bet.status === 5)
          .sort((a, b) =>
            a.profit < b.profit ? 1 : b.profit < a.profit ? -1 : 0
          )
      )
      .enter()
      .append("circle")
      .attr("class", "chart__bet")
      .attr("r", (d) => winnerRadius(Math.sqrt(d.profit)))
      .attr("cx", (d) => x(d.pre_payout))
      .attr("cy", (d) => y(d.amount))
      .style("fill", variables.colorPrimary)
      .style("opacity", 0.6)
      .on("mouseover", function (d) {
        select(this)
          .transition()
          .duration(200)
          .style("opacity", 0.9)
          .attr("r", (d) => winnerRadius(Math.sqrt(d.profit)) + 5);
        selectAll(".final-payout-" + d.id).style("opacity", 1);

        if (d.pre_payout === d.final_payout) {
          selectAll(".final-odds-legend--same").style("opacity", 1);
        }

        if (d.pre_payout < d.final_payout) {
          selectAll(".final-odds-legend--over").style("opacity", 1);
        }

        if (d.pre_payout > d.final_payout) {
          selectAll(".final-odds-legend--under").style("opacity", 1);
        }

        select(".bet-history__tooltip")
          .text(
            `${intComma(d.amount)} on ${d.selection.name} at ${oddsToString(
              d.odds
            )} in ${d.proposition.name}`
          )
          .classed("hide", false);
      })
      .on("mouseout", function (d) {
        select(this)
          .transition()
          .duration(300)
          .style("opacity", 0.6)
          .attr("r", (d) => winnerRadius(Math.sqrt(d.profit)));

        selectAll(".final-payout-" + d.id).style("opacity", 0);
        selectAll(".final-odds-legend").style("opacity", 0);

        select(".bet-history__tooltip").text("").classed("hide", true);
      });

    const wins = betHistory
      .filter((bet) => bet.status === 5)
      .sort((a, b) => (a.profit < b.profit ? 1 : b.profit < a.profit ? -1 : 0));

    if (wins.length > 0 && width > 350) {
      const biggestWin = wins[0];
      const annotations = [
        {
          note: {
            title: `${wins.length > 1 ? "Biggest win" : "Only win"}`,
            label: `${intComma(biggestWin.amount)} on ${
              biggestWin.selection.name
            }  at ${biggestWin.odds[0]}/${biggestWin.odds[1]} in ${
              biggestWin.proposition.name
            }`,
            bgPadding: 5,
          },
          subject: {
            radius: winnerRadius(Math.sqrt(biggestWin.profit)) + 5,
            radiusPadding: 0,
          },
          x: x(biggestWin.pre_payout),
          y: y(biggestWin.amount),
          dy: y(biggestWin.amount) < height / 2 ? 80 : -80,
          dx: x(biggestWin.pre_payout) < width / 2 ? 40 : -40,
          color: variables.colorRule,
        },
      ];
      const makeAnnotations = annotation()
        .type(annotationCalloutCircle)
        .notePadding(5)
        .annotations(annotations);
      svg.append("g").attr("class", "annotation-group").call(makeAnnotations);
    }

    // LEGEND

    if (this.props.losers.length > 0) {
      svg
        .append("line")
        .attr(
          "x1",
          0 -
            margin.left +
            (mobile || this.props.winners.length === 0 ? 0 : 300)
        )
        .attr(
          "x2",
          loserWidth -
            margin.left +
            (mobile || this.props.winners.length === 0 ? 0 : 300)
        )
        .attr("y1", 15 - margin.top + (mobile ? 0 : -17))
        .attr("y2", 15 + loserWidth - margin.top + (mobile ? 0 : -17))
        .style("stroke-width", 1)
        .style("stroke", variables.colorSuccess)
        .style("opacity", 0.6);

      svg
        .append("line")
        .attr(
          "x1",
          loserWidth -
            margin.left +
            (mobile || this.props.winners.length === 0 ? 0 : 300)
        )
        .attr(
          "x2",
          0 -
            margin.left +
            (mobile || this.props.winners.length === 0 ? 0 : 300)
        )
        .attr("y1", 15 - margin.top + (mobile ? 0 : -17))
        .attr("y2", 15 + loserWidth - margin.top + (mobile ? 0 : -17))
        .style("stroke-width", 1)
        .style("stroke", variables.colorCancel)
        .style("opacity", 0.6);

      svg
        .append("text")
        .attr("y", 22 - margin.top + (mobile ? 0 : -17))
        .attr(
          "x",
          10 -
            margin.left +
            (mobile || this.props.winners.length === 0 ? 0 : 300)
        )
        .attr("class", "legend-item")
        .text("Loser");
    }

    if (this.props.winners.length > 0) {
      svg
        .append("circle")
        .attr("r", 5)
        .attr("cx", 2.5 - margin.left)
        .attr("cy", 0 - margin.top)
        .style("fill", variables.colorPrimary)
        .style("opacity", 0.6);

      svg
        .append("text")
        .attr("x", 10 - margin.left)

        .attr("y", 5 - margin.top)
        .attr("class", "legend-item")
        .text("Winner (size proportional to payout)");
    }

    svg
      .append("rect")
      .attr("x", 0 - margin.left - 3)
      .attr("y", 39 - margin.top - 10 + (mobile ? 0 : -14))
      .attr("width", 11)
      .attr("height", 11)
      .attr("fill", variables.colorPrimaryPanel)
      .style("opacity", 0)
      .attr("class", "final-odds-legend final-odds-legend--under");

    svg
      .append("text")
      .attr("x", 10 - margin.left)
      .attr("y", 39 - margin.top + (mobile ? 0 : -14))
      .attr("class", "legend-item final-odds-legend final-odds-legend--under")
      .style("opacity", 0)
      .text(
        `Difference between bet odds and final odds ${
          mobile ? "" : "(bet was made at a bargain)"
        }`
      );

    svg
      .append("text")
      .attr("x", 10 - margin.left)
      .attr("y", 39 - margin.top + (mobile ? 0 : -14))
      .attr("class", "legend-item final-odds-legend final-odds-legend--over")
      .style("opacity", 0)
      .text(
        `Difference between bet odds and final odds ${
          mobile ? "" : "(bet was overvalued)"
        }`
      );

    if (mobile) {
      svg
        .append("text")
        .attr("x", 10 - margin.left)
        .attr("y", 56 - margin.top)
        .attr("class", "legend-item final-odds-legend final-odds-legend--under")
        .style("opacity", 0)
        .text("(bet was made at a bargain)");

      svg
        .append("text")
        .attr("x", 10 - margin.left)
        .attr("y", 56 - margin.top)
        .attr("class", "legend-item final-odds-legend final-odds-legend--over")
        .style("opacity", 0)
        .text("(bet was overvalued)");
    }
    svg
      .append("rect")
      .attr("x", 0 - margin.left - 3)
      .attr("y", 39 - margin.top - 10 + (mobile ? 0 : -14))
      .attr("width", 11)
      .attr("height", 11)
      .attr("fill", variables.colorErrorPanel)
      .style("opacity", 0)
      .attr("class", "final-odds-legend final-odds-legend--over");

    svg
      .append("line")
      .attr("class", "line line--dotted line--neutral")
      .attr("x1", 0 - margin.left)
      .attr("x2", 5 - margin.left)
      .attr("y1", 33 - margin.top + (mobile ? 0 : -14))
      .attr("y2", 33 - margin.top + (mobile ? 0 : -14))
      .style("stroke-width", 1)
      .style("stroke", variables.colorPrimary)
      .style("opacity", 0)
      .attr("class", "final-odds-legend  final-odds-legend--same");

    svg
      .append("text")
      .attr("x", 10 - margin.left)
      .attr("y", 39 - margin.top + (mobile ? 0 : -14))
      .attr("class", "legend-item final-odds-legend final-odds-legend--same")
      .style("opacity", 0)
      .text("Final odds (same as bet’s odds)");
  }

  render() {
    return (
      <div className="bet-history__outer">
        <div className="bet-history__tooltip hide" />
        <svg
          className={"bet-history chart"}
          ref={this.betHistory_el}
          width="100%"
          height={this.props.height || 400}
        />
      </div>
    );
  }
}

BetHistory.propTypes = {
  winners: PropTypes.array.isRequired,
  losers: PropTypes.array.isRequired,
};

export default BetHistory;
