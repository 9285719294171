// Third-party libraries
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

// Local dependencies
import { LoadingBar } from "../components/widgets";
import { Meta, NotLoggedIn } from "../utilities";
import { getHeaders, errorHandler, betsUrl } from "../apihelpers";
import BetItem from "../components/BetItem";
import { Bet } from "../shared/interfaces";

interface State {
  loading: boolean;
  unauthorized: boolean;
  bets: Array<Bet>;
}

class Bets extends Component {
  state:State = {
    bets: [],
    loading: true, // Prevents getting bets twice unnecessarily
    unauthorized: false, // Toggles to false on bad log in/expired token
  };

  _isMounted = false;

  getBets() {
    const headers = getHeaders();
    fetch(betsUrl, {
      method: "GET",
      headers,
    })
      .then(errorHandler)
      .then((data) => {
        if (this._isMounted) {
          this.setState({ bets: data, loading: false, unauthorized: false });
          localStorage.setItem("betHistory", JSON.stringify(data));
        }
      })
      .catch((error) => {
        console.log(error)
        this.props.logout();
        if (this._isMounted) {
          this.setState({ unauthorized: true });
        }
      });
  }

  componentDidMount() {
    this._isMounted = true;
    if (localStorage.betHistory) {
      this.setState(
        { bets: JSON.parse(localStorage.getItem("betHistory") || "[]") },
        () => this.getBets()
      );
    } else {
      this.getBets();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.props.loggedIn || this.state.unauthorized) {
      return (
        <Fragment>
          <Meta title="Bets" description="Your Rouleur Derby bets." />
          <NotLoggedIn />
        </Fragment>
      );
    }

    if (!this.state.bets) {
      return (
        <Fragment>
          <Meta title="Bets" description="Your Rouleur Derby bets." />
          <Headline player={this.props.player} />
          <LoadingBar />
        </Fragment>
      );
    }

    if (this.state.bets) {
      return (
        <Fragment>
          <Meta title="Bets" description="Your Rouleur Derby bets." />
          <Headline player={this.props.player} />
          {this.state.loading && <LoadingBar />}
          {this.state.bets.length === 0 ? (
            <p>
              You have no active bets. Check out the{" "}
              <Link to="/">current propositions</Link> and get in on the action!
            </p>
          ) : (
            <Table bets={this.state.bets} />
          )}
        </Fragment>
      );
    } else {
      return null;
    }
  }
}

function Headline(props) {
  return <h1>Active Bets for {props.player.screen_name}</h1>;
}

function Table(props) {
  return (
    <div className="sticky-table-outer">
      <table className="bet-table table--sticky">
        <thead>
          <tr>
            <th>Proposition</th>
            <th>Date of race</th>
            <th>Selection</th>
            <th>Odds</th>
            <th className="figure">Points</th>
          </tr>
        </thead>
        <tbody>
          {props.bets.map((bet, i) => <BetItem key={i} bet={bet} />)}
        </tbody>
      </table>
    </div>
  );
}

export default Bets;
