// Third-party libraries
import React, { Component } from "react";

// Project dependencies
import PropositionItem from "./PropositionItem";
import { getHeaders, propositionsUrl } from "../apihelpers";
import { LoadingBar } from "./widgets";

class PropositionMenuJustCards extends Component {
  state = {
    propositions: [],
    loading: false,
  };

  getPropositions() {
    if (localStorage.propositions) {
      this.setState({
        propositions: JSON.parse(localStorage.getItem("propositions")),
      });
    } else {
      this.setState({ loading: true });
    }

    let headers = getHeaders();
    fetch(propositionsUrl + "?" + Date.now(), {
      method: "GET",
      headers,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ propositions: data, loading: false });
        localStorage.setItem("propositions", JSON.stringify(data));
      })
      .catch((error) => console.log(error));
  }

  componentWillMount() {
    this.getPropositions();
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <LoadingBar />
        </>
      );
    } else if (
      this.state.propositions.filter((prop) => prop.hours_left > 0).length > 0
    ) {
      return (
        <>
          {!this.props.noHeader && (
            <h2 className="proposition-menu__h2 proposition-menu__h2--space-above">
              <span>Upcoming propositions</span>
            </h2>
          )}
          <div className="proposition-menu">
            {this.state.propositions
              .sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))
              .filter((proposition) => proposition.hours_left > 0)
              .slice(0, this.props.count)
              .map((proposition, i) => (
                <PropositionItem key={i} proposition={proposition} />
              ))}
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}

export default PropositionMenuJustCards;
