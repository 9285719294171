import React from "react";
import variables from "../styleVariables";

const WapIcon = ({
  color = variables.colorSecondary,
  foreground = "#ffffff",
}) => (
  <svg
    className="wap-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="800"
    height="800"
    viewBox="0 0 800 800"
  >
    <polygon
      fill={color}
      // eslint-disable-next-line max-len
      points="400,-5.9 424.4,45 455.7,-2 472.8,51.8 510.3,9.6 519.7,65.2 562.7,28.7 564.3,85.1 612,54.9 605.7,111 657.1,87.7 643.1,142.4 697.3,126.5 675.8,178.6 731.6,170.4 703.1,219.1 759.5,218.8 724.5,263 780.4,270.5 739.6,309.5 793.9,324.7 748.1,357.5 799.8,380.2 749.8,406.3 797.8,435.9 744.7,454.9 788.1,490.9 732.9,502.3 770.9,544 714.6,547.6 746.4,594.1 690.2,589.8 715.2,640.4 660.1,628.3 677.9,681.9 625,662.2 635.1,717.7 585.5,690.9 587.8,747.3 542.4,713.9 536.8,770 496.5,730.6 483.2,785.4 448.7,740.7 427.9,793.2 400,744.1 372.1,793.2 351.3,740.7 316.8,785.4 303.5,730.6 263.2,770 257.6,713.9 212.2,747.3 214.5,690.9 164.9,717.7 175,662.2 122.1,681.9 139.9,628.3 84.8,640.4 109.8,589.8 53.6,594.1 85.4,547.6 29.1,544 67.1,502.3 11.9,490.9 55.3,454.9 2.2,435.9 50.2,406.3 0.2,380.2 51.9,357.5 6.1,324.7 60.4,309.5 19.6,270.5 75.5,263 40.5,218.8 96.9,219.1 68.4,170.4 124.2,178.6 102.7,126.5 156.9,142.4 142.9,87.7 194.3,111 188,54.9 235.7,85.1 237.3,28.7 280.3,65.2 289.7,9.6 327.2,51.8 344.3,-2 375.6,45"
    />
    <path
      fill={foreground}
      className="st0"
      d="M549.1,574.1h-68.4L400,340.3l-80.7,233.8h-68.4l-122-359.9h84.2l74,242l80.1-243h67.4l80.1,243l74-242h82.2 L549.1,574.1z"
    />
  </svg>
);

export default WapIcon;
