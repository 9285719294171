// Third-party libraries
import React, { Component, Fragment } from "react";

// Project dependencies
import { getHeaders, digestUnsubscribeUrl, errorHandler } from "../apihelpers";
import { Meta } from "../utilities";
import { LoadingBar } from "../components/widgets";

class LeagueOptout extends Component {
  state = {
    loaded: false,
    match: false,
    handle: "",
  };
  _isMounted = false;
  token = this.props.match.params.token;

  checkToken() {
    const headers = getHeaders();
    fetch(digestUnsubscribeUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        token: this.token,
      }),
    })
      .then(errorHandler)
      .then((data) => {
        if (this._isMounted) {
          if (data.error) {
            this.setState({ match: false, loaded: true });
          } else {
            window.gtag("event", "unsubscribe", {
              event_category: "digest",
              event_label: "via magic link",
            });
            this.setState({ match: true, loaded: true, handle: data.handle });
          }
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({ match: false, loaded: true });
        }
        console.log(error);
      });
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkToken();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Fragment>
        <Meta
          title="Rouleur Derby Digest Unsubscribe"
          description="No hard feelings."
        />
        <h1>Unsubscribing from Rouleur Derby Digest</h1>
        {this.state.loaded || <LoadingBar />}

        {this.state.match && (
          <p>
            Gotcha. Rouleur Derby will no longer email you daily summaries. If
            you change your mind, you can adjust your preference on your{" "}
            <a href={`/${this.state.handle}`}>profile page</a>.
          </p>
        )}
        {this.state.loaded || <LoadingBar />}

        {this.state.loaded && !this.state.match && (
          <p>
            That does not appear to be a valid opt-out token.{" "}
            <a href="mailto:info@rouleurderby.com">Contact Rouleur Derby</a> if
            you need assistance opting out of league invitations.
          </p>
        )}
      </Fragment>
    );
  }
}

export default LeagueOptout;
