import React, {Fragment} from "react";
import PropositionMenuJustCards from "../components/PropositionMenuJustCards";
import {Meta, GAEvent} from "../utilities"

function FileNotFound() {

  return (
    <Fragment>
      <Meta
            title="404"
            description="Oops."
      />
      <GAEvent action="404" category="errors" label={window.location.pathname} />
    <section className="four-oh-four">
      <h1>404</h1>
      <p>Sorry, that does not appear to be a valid link.</p>
      <PropositionMenuJustCards count={3} />
    </section>
    </Fragment>
  );
}

export default FileNotFound;
