import React from "react";

function Option({ option, handleChange, checked, reason }) {
  // If this is part of the form of unscratched riders
  if (handleChange) {
    return (
      <div className="option">
        <div className="option__main option__main--single-column">
          <input
            type="checkbox"
            id={`option-${option.id}-checkbox`}
            name={`scratch-${option.id}`}
            onChange={handleChange}
            checked={checked}
          />
          <label
            className="option__name"
            htmlFor={`option-${option.id}-checkbox`}
          >
            {option.option.name}
          </label>

          <label
            className="option__scratch-reason-input"
            htmlFor={`option-${option.id}-reason`}
          >
            Reason
            <input
              type="text"
              id={`option-${option.id}-reason`}
              name={`reason-${option.id}`}
              onChange={handleChange}
              value={reason}
            />
          </label>
        </div>
      </div>
    );
  }

  // Otherwise it's just a list of scratched riders and their reasons.
  else {
    return (
      <div className="option">
        <div className="option__main option__main--single-column">
          <label className="option__name">{option.option.name}</label>
          <p className="option__scratch-reason">{option.scratch_reason}</p>
        </div>
      </div>
    );
  }
}

export default Option;
