// Third-party libraries
import React from "react";

// Project dependencies

function HomeWelcome(props) {
  if (navigator.userAgent === "ReactSnap") {
    return null;
  }

  return (
    <section
      onClick={props.hideMobileSidebar}
      className={`home-welcome alert alert alert--full-width 
      ${props.showMobileSidebar ? "alert--full-width-show-mobile-sidebar" : ""}
      ${
        props.location.pathname.indexOf("prop") > 0
          ? "home-welcome--prop-page"
          : ""
      }`}
    >
      <h1 className="home-welcome__h1">
        Welcome to Rouleur Derby
        <span className="home-welcome__h1--secondary">
          , the exciting game that lets you make fantasy bets on professional
          cycling.
        </span>
      </h1>

      <p>
        As of spring 2024, Rouleur Derby is taking a little break. Thanks so
        much for playing and helping make this{" "}
        <em>almost certainly the best fantasy cycling game in the world</em>.
      </p>
      <p>
        Thanks also to{" "}
        <a
          href="https://www.wideanglepodium.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Wide Angle Podium
        </a>{" "}
        for its years of sponsorship and support.
      </p>

      <p>
        To be alerted when Rouleur Derby returns, be sure{" "}
        <a
          href="https://mailchi.mp/8a097990027d/subscribe"
          target="_blank"
          rel="noopener noreferrer"
        >
          you’re subscribed to its newsletter
        </a>
        . Until then, enjoy the races!
      </p>
    </section>
  );
}

export default HomeWelcome;
