// Third-party libraries
import React, { Component, Fragment } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Project dependencies
import { LoadingBar } from "../components/widgets";
import {
  getHeaders,
  errorHandler,
  propositionUrl,
  scoreUrl,
} from "../apihelpers";
import { Meta, NotStaff } from "../utilities";
import ScoreOption from "../components/ScoreOption";

class Score extends Component {
  state = {
    loading: false,
    winners: [],
    hasCorrectWinnerCount: false,
    scored: false,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getProposition(slug) {
    const headers = getHeaders();
    fetch(propositionUrl + "/" + slug, {
      method: "GET",
      headers,
    })
      .then(errorHandler)
      .then((data) => {
        this.setState({ proposition: data });
      })
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    const { slug } = this.props.match.params;
    this.getProposition(slug);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    // Winners get stored in state. If it's a show proposition, up to three option_data IDs will be stored there.
    // Otherwise, just one. This is what gets passed to the back end.
    this.setState(
      {
        [name]: value,
      },
      () => {
        let winners = [];
        let correctWinnerCount = this.state.proposition.type === 3 ? 3 : 1; // prop.type of 3 means show

        if (this.state.proposition.type !== 3) {
          winners = [this.state.winner.replace("score-", "")];
        } else {
          winners = Object.keys(this.state)
            .filter(
              (key) => key.startsWith("score-") && this.state[key] === true
            )
            .map((key) => parseInt(key.replace("score-", "")));
        }
        let hasCorrectWinnerCount =
          winners.length > 0 && winners.length <= correctWinnerCount;
        this.setState({
          winners: winners,
          hasCorrectWinnerCount: hasCorrectWinnerCount,
        });
      }
    );
  }

  handleSubmit(event) {
    const headers = getHeaders();
    this.setState({ loading: true });
    fetch(scoreUrl + "/" + this.state.proposition.slug, {
      method: "POST",
      headers,
      body: JSON.stringify(this.state.winners),
    })
      .then(errorHandler)
      .then((response) => {
        if (response.error === false) {
          this.setState({ loading: false, scored: true });
        }
      });

    event.preventDefault();
  }

  render() {
    if (
      this.props.player &&
      !this.props.player.is_staff &&
      this.state.proposition
    ) {
      return (
        <Fragment>
          <Meta
            title={`Non-staff visit to scoring: ${this.state.proposition.question}`}
            description="Scoring a rider."
          />
          <NotStaff />
        </Fragment>
      );
    }

    if (
      this.state.scored ||
      (this.state.proposition && this.state.proposition.status === 4)
    ) {
      return (
        <p>
          <Link to={`/prop/${this.state.proposition.slug}`}>
            This proposition
          </Link>{" "}
          has been scored.
        </p>
      );
    } else if (this.state.proposition && this.state.proposition.coming) {
      return (
        <Fragment>
          <Meta
            title={`Scoring: ${this.state.proposition.question}`}
            description="Scoring a rider."
          />
          <h1>{this.state.proposition.question}</h1>
          <p>
            <Link to={`/prop/${this.state.proposition.slug}`}>
              This proposition
            </Link>{" "}
            is not ready for scoring yet.
          </p>
        </Fragment>
      );
    } else if (this.state.proposition) {
      return (
        <article>
          <Helmet>
            <title>Score: {this.state.proposition.question}</title>
          </Helmet>

          <h1>{this.state.proposition.question}</h1>

          <h2>Active Riders</h2>

          {!this.state.loading && (
            <form onSubmit={this.handleSubmit}>
              {this.state.proposition.options
                .filter((option) => option.scratch === false)
                .map((option, i) => (
                  <ScoreOption
                    key={i}
                    option={option}
                    handleChange={this.handleChange}
                    propositionType={
                      this.state.proposition.type === 3 ? "show" : "winner"
                    }
                    checked={this.state["score-" + option.id] || false}
                  />
                ))}

              <button
                className={`button--bet ${
                  this.state.loading ? "button--hide" : ""
                }`}
                disabled={!this.state.hasCorrectWinnerCount}
              >
                Score this proposition
              </button>
            </form>
          )}
        </article>
      );
    } else {
      return <LoadingBar />;
    }
  }
}

Score.propTypes = {
  loading: PropTypes.bool,
  correctWinnerCount: PropTypes.bool,
  winners: PropTypes.arrayOf(PropTypes.number),
};

export default Score;
