// Third-party libraries
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { intComma } from "humanize-plus";

// Project dependencies
import { getBetCapacity, hoursLeft } from "../utilities";

function PropositionItem({ player, proposition, archive }) {
  var slug = proposition.slug,
    betsOnThisProposition,
    amountBetOnThisProposition;

  if (player && player.id) {
    betsOnThisProposition = player.active_bets.filter(
      bet => bet.proposition.slug === proposition.slug
    );

    amountBetOnThisProposition =
      betsOnThisProposition.length > 0
        ? betsOnThisProposition.reduce((a, b) => ({
            amount: a.amount + b.amount
          })).amount
        : 0;
  }

  const betCapacity = getBetCapacity(
    proposition.action, amountBetOnThisProposition)
  
  return (
    
    <Link
      to={`/prop/${slug}`}
      className={
        player && player.id
          ? "prop-card prop-card--logged-in"
          : "prop-card"
      }
    >
      <article>
        {proposition.hours_left < 24 && proposition.hours_left > 0 && (
          <div className="prop-card__alert">
            {hoursLeft(proposition.hours_left)} to go!
          </div>
        )}

        {proposition.race &&
          (proposition.contest && proposition.race.name !== proposition.contest.name) && (
            <div className="prop-card__race">{proposition.race.name}</div>
          )}
        <h1 className="prop-card__question">{proposition.question}</h1>

        <div className="prop-card__meta">
          <div className="prop-card__meta-item">
            <strong>Date</strong>
            {proposition.date_string.split(",")[0]},
            <span className="nobr">
              {proposition.date_string.split(",")[1]}
            </span>
          </div>
          {!archive && (
            <div className="prop-card__meta-item">
              <strong>Time left</strong>
              {hoursLeft(proposition.hours_left)}
            </div>
          )}
          {archive && (
            <div className="prop-card__meta-item">
              <strong>
                Winner
              </strong>
              {proposition.winner ? proposition.winner.name : "No winner"}
              {proposition.winner2 ? `, ${proposition.winner2.name}` : ""}
              {proposition.winner3 ? `, ${proposition.winner3.name}` : ""}

            </div>
          )}

          {player && player.id && (
            <Fragment>
              <div
                className={
                  amountBetOnThisProposition === 0
                    ? "prop-card__meta-item prop-card__meta-item--alert"
                    : "prop-card__meta-item"
                }
              >
                <strong>Your Action</strong>
                {intComma(amountBetOnThisProposition)}
              </div>
              <div
                className={
                  betsOnThisProposition.length === 0
                    ? "prop-card__meta-item prop-card__meta-item--alert"
                    : "prop-card__meta-item"
                }
              >
                <strong>You may bet</strong>
                {intComma(betCapacity)}
              </div>
            </Fragment>
          )}
        </div>

        <div className={`
          prop-card__button
          ${(proposition.hours_left === 0 || betCapacity === 0)
            ? "prop-card__button--inactive" : ""}`}>
          {(proposition.hours_left > 0 && betCapacity > 0)
            ? "Bet now" : "View"}
        </div>
      </article>
    </Link>
  );
}

export default PropositionItem;
