let api = "//127.0.0.1:8000/";

if (window.location.hostname === "localhost") {
  api = "//127.0.0.1:8000/";
}
if (window.location.hostname === "www.rouleurderby.com") {
  api = "//api.rouleurderby.com/";
}
if (window.location.hostname === "dev.rouleurderby.com") {
  api = "//api-dev.rouleurderby.com/";
}
if (navigator.userAgent === "ReactSnap") {
  // The pre-rendering crawler
  api = "https://api.rouleurderby.com/";
}

export const apiRoot = api;
export const adjustmentUrl = apiRoot + "adjustment";
export const betsUrl = apiRoot + "self/bets";
export const allBetsUrl = apiRoot + "self/bets/all";
export const bonusUrl = apiRoot + "bonus";
export const contestUrl = apiRoot + "contests";
export const reminderUnsubscribeUrl = apiRoot + "unsubscribe/reminders";
export const digestUnsubscribeUrl = apiRoot + "unsubscribe/digest";
export const digestStatusUrl = apiRoot + "digest/status";
export const digestSendUrl = apiRoot + "send/digest";
export const leagueOptoutUrl = apiRoot + "league/optout";
export const leagueUrl = apiRoot + "league";
export const leaguesUrl = apiRoot + "leagues";
export const loginUrl = apiRoot + "auth/token/login/";
export const logoutUrl = apiRoot + "auth/token/logout/";
export const newPlayerUrl = apiRoot + "auth/users/";
export const newPlayerPrefsUrl = apiRoot + "self/new/preferences";
export const passwordResetConfirmUrl = apiRoot + "auth/password/reset/confirm/";
export const passwordResetUrl = apiRoot + "auth/password/reset/";
export const paybackLoanUrl = apiRoot + "loan/payback";
export const playerUrl = apiRoot + "players";
export const propositionsUrl = apiRoot + "propositions";
export const propositionUrl = apiRoot + "propositions";
export const requestLoanUrl = apiRoot + "loan/request";
export const scoreUrl = apiRoot + "scores";
export const scratchUrl = apiRoot + "scratches";
export const selfUrl = apiRoot + "players/self";
export const transactionsUrl = apiRoot + "self/transactions";

export const errorHandler = (response) => {
  if (response.ok) {
    return response.json();
  } else {
    throw new Error("Could not reach the API: " + response.statusText);
  }
};

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getHeaders = (generic = false) => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (getCookie("token").length > 0 && !generic) {
    headers.authorization = "Token " + getCookie("token");
  }
  return headers;
};
