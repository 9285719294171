import React, { Fragment } from "react";
import { Meta } from "../utilities";

const Changes2019 = () => (
    <Fragment>
      <Meta
        title="A New Way of Playing Rouleur Derby"
        description="Introducing new rules for the 2019-2020 season."
      />
      <h1>Introducting a New Way of Playing Rouleur Derby</h1>
      <h4>What’s new?</h4>
      <p>
        <strong>Short version:</strong> Winning payouts are now based on the
        odds at the time a bet was made, not at the time betting closed.
      </p>
      <p>
        <strong>Long version:</strong> Historically, Rouleur Derby has operated
        as a zero-sum parimutuel. Odds changed depending on how many points had
        been bet on any given option, and players earned points based on where
        the odds settled. This is similar to the experience of betting at the
        Kentucky Derby.
      </p>
      <p>
        For the 2020 season, Rouleur Derby is experimenting with a fixed-odds
        system. Payouts will be determined by the odds at the time of each bet,
        not the time of the race. This is similar to the way sports betting
        works in Las Vegas or, better yet, the{" "}
        <a
          href="https://www.cyclingnews.com/features/flutter-in-flanders-taking-bets-at-the-classics/"
          rel="noopener noreferrer"
          target="_blank"
        >
          sidewalks of Flanders
        </a>
        .
      </p>
      <p>
        Let’s say you made a bet at 30/1 but later the odds narrowed to 10/1.
        Previously you would have won 10 points for every point bet. Now you
        will win 30. (Conversely, if you make a bet at 10/1 and the odds widen
        to 30/1, you will now win only 10 points.)
      </p>
      <p>
        Under this system, odds will continue to change, based on where the
        action is going, but change will not be immediate with each bet.
        Instead, odds updates will take place at various, semirandom intervals,
        increasing in frequency as action picks up and as betting nears its
        close.
      </p>

      <h4>Will this be more fun?</h4>
      <p>Yes, this will be more fun.</p>
      <p>This system will have the following benefits:</p>
      <ul>
        <li>It will encourage more, earlier betting.</li>
        <li>It will better reward players who spot undervalued outcomes.</li>
        <li>Odds will be less confusing to new players.</li>
      </ul>
      <h4>Why aren’t odds updating as frequently?</h4>
      <p>
        In testing, it quickly became clear that one could easily manipulate the
        parimutuel in order to unfairly guarantee a profit. Making the odds
        updates less frequent will help prevent such hedge doping.
      </p>
      <h4>What’s the deal with the betting caps?</h4>
      <p>
        A player’s bets on a given proposition may not exceed 20% of the
        proposition’s total action. This, too, is an effort to prevent unfair
        manipulation of the odds.
      </p>
      <h4>Should my strategy change?</h4>
      <p>
        Probably not. Rouleur Derby still boils down to making the most accurate
        predictions. Make correct predictions and odds are you’ll do just fine.
      </p>
    </Fragment>
  );

export default Changes2019;
