/* eslint-disable no-console */
// Third-party libraries
import React, { Component } from "react";
import { intComma } from "humanize-plus";

// Project dependencies
import {
  getHeaders,
  digestStatusUrl,
  digestSendUrl,
  errorHandler,
} from "../../apihelpers";
import { Meta } from "../../utilities";
import { LoadingBar } from "../../components/widgets";

class DigestStatus extends Component {
  state = {
    propositionCount: 0,
    playerCount: 0,
    loaded: true,
    recipients: [],
    sending: false,
    sendCount: null,
  };
  _isMounted = false;
  token = this.props.match.params.token;

  componentDidMount() {
    this._isMounted = true;
    const headers = getHeaders();

    fetch(digestStatusUrl, {
      method: "GET",
      headers,
    })
      .then(errorHandler)
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            loaded: true,
            propositionCount: data.propositionCount,
            playerCount: data.playerCount,
            recipients: data.recipients,
            sendCount: null,
            sending: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSend() {
    this.setState({ sending: true });
    const headers = getHeaders();
    fetch(digestSendUrl, {
      method: "GET",
      headers,
    })
      .then(errorHandler)
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            loaded: true,
            sendCount: data.sendCount,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <>
        <Meta title="Rouleur Derby Digest Status" />
        <h1>Daily Digest Status</h1>
        {this.state.loaded || <LoadingBar />}
        {this.props.loggedIn && this.props.player.is_staff ? (
          this.state.propositionCount > 0 && !this.state.sendCount ? (
            <>
              <p>
                There{" "}
                {this.state.propositionCount > 1 ? (
                  <>
                    are <strong>{this.state.propositionCount}</strong>{" "}
                    propositions
                  </>
                ) : (
                  <>
                    is <strong>1</strong> proposition
                  </>
                )}{" "}
                ready to announce.
              </p>
              <p>
                Announcements will go to{" "}
                {this.state.playerCount > 1 ? (
                  <>
                    <strong>{this.state.playerCount}</strong> players
                  </>
                ) : (
                  <>
                    <strong>{this.state.playerCount}</strong> player
                  </>
                )}
                .
              </p>
              <button
                onClick={() => this.handleSend()}
                disabled={this.state.sending}
              >
                Send digest
              </button>

              <h4>Recipients</h4>
              <table style={{ maxWidth: "300px" }}>
                {this.state.recipients
                  .sort((a: any, b: any) => b.net - a.net)
                  .map((player: any) => (
                    <tr key={player.handle}>
                      <td>
                        <a href={`/${player.handle}`} target="_blank">
                          {player.handle}
                        </a>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {intComma(player.net)}
                      </td>
                    </tr>
                  ))}
              </table>
            </>
          ) : (
            !this.state.sendCount && (
              <p>There are no propositions ready to announce.</p>
            )
          )
        ) : (
          <p>You must be authorized to view this page.</p>
        )}
        {this.state.sendCount && (
          <p>Great. The digest was sent to {this.state.sendCount} players.</p>
        )}
      </>
    );
  }
}

export default DigestStatus;
