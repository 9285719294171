import React, { memo } from "react";

export const LoadingBar = () => (
  <div className="progress-bar" role="progressbar">
    <div className="progress-bar__line" />
    <div className="progress-bar__subline progress-bar__inc" />
    <div className="progress-bar__subline progress-bar__dec" />
  </div>
);

export const LoadingSpinner = () => <div className="progress-spinner"></div>;

export const Invite = memo(({ player }) => {
  if (player.bet_tally > 0 && player.invitations_left > 0) {
    return (
      <aside className="alert">
        <h3>Your friends are missing out</h3>
        <p>
          Rouleur Derby is more fun with more players. Invite your friends with
          the following link and earn up to three 100-point rewards:{" "}
          <strong>
            <span className="user-select">
              rouleurderby.com?invite={player.invite_token}
            </span>
          </strong>
          &nbsp;.
        </p>
      </aside>
    );
  } else {
    return null;
  }
});
