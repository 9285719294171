// Third-party libraries
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Project dependencies
import { Meta } from "../utilities";
import { getHeaders, bonusUrl, errorHandler } from "../apihelpers";
import LoginForm from "../components/LoginForm.js";
import PropositionMenuJustCards from "../components/PropositionMenuJustCards.js";

class Bonus extends Component {
  state = {
    match: false,
    loaded: false,
    errorMessage: ""
  };
  _isMounted = false;
  code = this.props.match.params.code;

  checkBonus() {
    const headers = getHeaders();
    fetch(bonusUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({ code: this.code })
    })
      .then(errorHandler)
      .then(data => {
        window.gtag("event", data.error ? "error" : "success", {
          event_category: "bonus redemptions",
          event_label: data.message
        });

        if (this._isMounted) {
          this.setState({
            loaded: true,
            match: data.error === false,
            message: data.message
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._isMounted = true;

    this.checkBonus();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    // Check to see if the player is newly logged in
    if (prevProps.loggedIn === false && this.props.loggedIn === true) {
      this.checkBonus();
    }
  }
  render() {
    if (!this.props.loggedIn) {
      return (
        <Fragment>
          <Meta title="Bonus redemption (not logged in)" />
          <h1>Please log in</h1>
          <p>
            It looks like you’re trying to redeem a bonus. Please log in first.
          </p>
          <LoginForm afterLogin={this.props.afterLogin} />
        </Fragment>
      );
    }

    if (this.state.loaded === false) {
      return null;
    } else {
      if (this.state.match === false) {
        return (
          <Fragment>
            <Meta title="Bonus redemption (error)" />
            <h1>Hmmm…</h1>
            {this.state.message && <p>{this.state.message}</p>}
            <PropositionMenuJustCards count={3} />
          </Fragment>
        );
      } else if (this.state.match) {
        return (
          <Fragment>
            <Meta title="Bonus redemption" />
            <h1>Awesome!</h1>
            {this.state.message && <p>{this.state.message}</p>}
            <PropositionMenuJustCards count={3} />
          </Fragment>
        );
      }
    }
  }
}

Bonus.propTypes = {
  player: PropTypes.object,
  loggedIn: PropTypes.bool
};

export default Bonus;
