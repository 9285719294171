// Third-party libraries
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

// Project dependencies
import { getHeaders, leagueOptoutUrl, errorHandler } from "../apihelpers";
import { Meta } from "../utilities";
import { LoadingBar } from "../components/widgets";

class LeagueOptout extends Component {
  state = {
    loaded: false,
    match: false,
  };
  _isMounted = false;
  token = this.props.match.params.token;

  checkToken() {
    const headers = getHeaders();
    fetch(leagueOptoutUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        token: this.token,
      }),
    })
      .then(errorHandler)
      .then((data) => {
        if (this._isMounted) {
          if (data.error) {
            this.setState({ match: false, loaded: true });
          } else {
            window.gtag("event", "opt out of invites", {
              event_category: "league",
            });
            this.setState({ match: true, loaded: true });
          }
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({ match: false, loaded: true });
        }
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkToken();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Fragment>
        <Meta
          title="League Invitation Opt-out"
          description="No hard feelings."
        />
        <h1>Opting Out of League Invitations</h1>
        {this.state.loaded || <LoadingBar />}

        {this.state.match && (
          <p>
            Gotcha. Rouleur Derby will no longer email you league invitations.
            If you want to be part of a league, you will need to{" "}
            <Link to="/leagues">start your own</Link>, or a league owner will
            have to personally invite you.
          </p>
        )}
        {this.state.loaded || <LoadingBar />}

        {this.state.loaded && !this.state.match && (
          <p>
            That does not appear to be a valid opt-out token.{" "}
            <a href="mailto:info@rouleurderby.com">Contact Rouleur Derby</a> if
            you need assistance opting out of league invitations.
          </p>
        )}
      </Fragment>
    );
  }
}

export default LeagueOptout;
