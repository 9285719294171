import React from "react";

import variables from "../styleVariables";

const SuccessIcon = ({
  color = variables.colorSuccess,
  foreground = "#ffffff",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" viewBox="0 0 50 50">
    <circle cx="25" cy="25" r="25" fill={color} />
    <polyline
      points="38,15 22,33 12,25"
      fill="none"
      stroke={foreground}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
);

export default SuccessIcon;
