// Third-party libraries
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// Project dependencies
import {
  loginUrl,
  setCookie,
  getHeaders,
  errorHandler,
} from "../apihelpers.js";
import PropositionMenuJustCards from "./PropositionMenuJustCards.js";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      badLogin: false,
      showBadLoginAlert: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  uniqueId = 0;

  login(username, password) {
    const headers = getHeaders();
    fetch(loginUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({ username, password }),
    })
      .then(errorHandler)
      .then((data) => {
        if (data.auth_token) {
          window.gtag("event", "login", { method: "djoser" });
          setCookie("token", data.auth_token, 30);
          this.props.afterLogin();
        }
      })
      .catch(() => this.setState({ badLogin: true, showBadLoginAlert: true }));
  }

  componentWillMount() {
    // Allows each instance to have unique names and IDs
    this.uniqueId = Math.random().toString(36).substring(7);

    // If username gets prepopulated
    if (this.props.username) {
      this.setState({ username: this.props.username });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: name === "username" ? value.toLowerCase() : value,
      badLogin: false,
    });
  }

  handleSubmit(event) {
    this.login(this.state.username, this.state.password);
    event.preventDefault();
  }

  render() {
    if (this.props.loggedIn === true) {
      return (
        <Fragment>
          <p>
            You are logged in. Here are some upcoming propositions you can bet
            on.
          </p>
          <PropositionMenuJustCards count="3" />
        </Fragment>
      );
    }
    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.showBadLoginAlert && (
          <p className="login__error-message">Bad login</p>
        )}

        <label htmlFor={"username-" + this.uniqueId}>Username</label>
        <input
          onChange={this.handleChange}
          name="username"
          type="text"
          value={this.state.username}
          id={"username-" + this.uniqueId}
        />

        <label htmlFor={"password-" + this.uniqueId}>Password</label>
        <input
          onChange={this.handleChange}
          name="password"
          type="password"
          value={this.state.password}
          id={"password-" + this.uniqueId}
        />

        <button
          className={`login__submit ${
            this.state.badLogin ? "login__submit--error" : ""
          }`}
        >
          Log in
        </button>
        <div>
          <NavLink
            activeClassName="login__forget--active"
            to="/rd/password/reset"
            className="login__forget"
          >
            Forgot password?
          </NavLink>
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  badLogin: PropTypes.bool,
  showBadLoginAlert: PropTypes.bool,
};

export default LoginForm;
