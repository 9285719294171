// Third-party libraries
// eslint-disable-next-line no-unused-vars
import React, { memo } from "react";
// eslint-disable-next-line no-unused-vars
import Helmet from "react-helmet";
import { intcomma, pluralize } from "humanize-plus";
// eslint-disable-next-line import/no-unresolved
import { PCT_MAX, MINIMUM_ALLOWED } from "./shared/constants";

let ga: string = "UA-1814180-7";
if (window.location.hostname === "localhost") {
  ga = "UA-1814180-7";
}
if (window.location.hostname === "www.rouleurderby.com") {
  ga = "UA-1814180-4";
}
if (window.location.hostname === "dev.rouleurderby.com") {
  ga = "UA-1814180-7";
}
export const googleAnalyticsID = ga;

export const NotStaff = () => <p>This page is not available to you.</p>;

export const NotLoggedIn = () => <p>You must be logged in to see this page.</p>;

export const PropWinners = ({
  proposition,
  withOdds = false,
  verbose = false,
}) => {
  if (proposition.winner3) {
    return (
      <>
        <strong>{proposition.winner.name}</strong>{" "}
        {withOdds && `(${proposition.winner_odds})`},{" "}
        <strong>{proposition.winner2.name}</strong>{" "}
        {withOdds && `(${proposition.winner2_odds})`}, and{" "}
        <strong>{proposition.winner3.name}</strong>{" "}
        {withOdds && `(${proposition.winner3_odds})`}{" "}
        {verbose && "were declared winners."}
      </>
    );
  } else if (proposition.winner2) {
    return (
      <>
        <strong>{proposition.winner.name}</strong>{" "}
        {withOdds && `(${proposition.winner_odds})`} and{" "}
        <strong>{proposition.winner2.name}</strong>{" "}
        {withOdds && `(${proposition.winner2_odds})`}{" "}
        {verbose && "were declared winners of this propostion."}
      </>
    );
  } else if (proposition.winner) {
    return (
      <>
        <strong>{proposition.winner.name}</strong>{" "}
        {withOdds && `(${proposition.winner_odds})`}{" "}
        {verbose && "was declared the winner of this proposition."}
      </>
    );
  } else {
    return <>No winner was verified.</>;
  }
};

export const hoursLeft = (hours: number) => {
  const days: number = Math.ceil(hours / 24);
  const weeks: number = Math.floor(hours / (24 * 7));
  if (hours <= 0) {
    return <>Closed</>;
  } else if (hours > 0 && hours < 1) {
    return <>Less than an hour</>;
  } else if (hours < 12) {
    return <>Less than {Math.ceil(hours)} hours</>;
  } else if (hours < 24) {
    return <>Less than a day</>;
  } else if (hours < 36) {
    return (
      <>
        Less than a <span className="nobr">day and a half</span>
      </>
    );
  } else if (days < 15) {
    return <>{days} days</>;
  } else if (weeks > 0) {
    if (days - weeks * 7 > 0) {
      return (
        <>
          {weeks} {pluralize(weeks, "week")},{" "}
          <span className="nobr">
            {days - weeks * 7} {pluralize(days - weeks * 7, "day")}
          </span>
        </>
      );
    } else {
      return <>{`${weeks} ${pluralize(weeks, "week")}`}</>;
    }
  }
};

export const Meta = memo(
  ({
    title,
    description,
    pageview = true,
    image,
  }: {
    title: string;
    description: string;
    pageview: boolean;
    image?: string;
  }) => {
    // Not ideal to have gtag here, but it lets us fire gtag with the latest title value.
    // Use memo so it doesn't fire twice.
    if (pageview && navigator.userAgent !== "ReactSnap") {
      let w: any;
      w = window;
      const gtag = w.gtag;
      if (typeof gtag === "function") {
        gtag("config", googleAnalyticsID, {
          page_title: title,
          page_location: window.location.href,
          page_path: window.location.pathname,
        });
      }
    }

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {image && <meta name="og:image" content={image} />}
        {image && <meta name="twitter:image" content={image} />}        
      </Helmet>
    );
  }
);

export const payoutToOdds = (payout: number): [number, number] => {
  function gcd(a: number, b: number) {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  }

  function findNearestRoundPayout(value: number): number {
    //These are payouts that return non-annoying odds
    const values = [
      11,
      13,
      14,
      16,
      20,
      25,
      33,
      35,
      40,
      50,
      67,
      75,
      80,
      100,
      120,
      125,
      133,
      140,
      150,
      200,
      250,
      300,
      350,
      367,
      400,
      450,
      500,
      550,
      600,
      650,
      700,
      750,
      800,
      850,
      900,
      950,
      1000,
    ];
    var nearest: number = -1;
    var diff = -1;
    var i = 0;
    while (i < values.length) {
      var newDiff = Math.abs(value - values[i]);
      if (diff === -1 || newDiff < diff) {
        nearest = values[i];
        diff = newDiff;
      }
      i += 1;
    }
    return nearest;
  }
  function findNearestFifth(value: number): number {
    // Nearest divisor by 5 between 20 and 100
    const values: Array<number> = [
      20,
      25,
      30,
      35,
      40,
      45,
      50,
      55,
      60,
      65,
      70,
      75,
      80,
      85,
      90,
      95,
      100,
    ];
    let nearest: number = -1;
    let diff: number = -1;
    let i: number = 0;
    while (i < values.length) {
      let newDiff = Math.abs(value - values[i]);
      if (diff === -1 || newDiff < diff) {
        nearest = values[i];
        diff = newDiff;
      }
      i += 1;
    }
    return nearest;
  }

  if (payout <= 1.1) {
    return [1, 10];
  }
  if (payout > 5) {
    payout = Math.round(payout);
  }

  let left: number = Math.round((payout - 1) * 100);
  let right = 100;
  if (left < 1000) {
    left = findNearestRoundPayout(left);
  } else {
    left = Math.round(parseFloat((left / 100).toPrecision(2)) * 100); // Round to two sigfigs
  }
  const greatest = gcd(left, right);
  left /= greatest;
  right /= greatest;
  if (right === 1 && left >= 20 && left <= 100) {
    left = findNearestFifth(left);
  }

  // Do some manual rounding

  if (left === 11 && right === 100) {
    left = 1;
    right = 9;
  } else if (left === 13 && right === 100) {
    left = 1;
    right = 8;
  } else if (left === 67 && right === 100) {
    left = 2;
    right = 3;
  } else if (left === 133 && right === 100) {
    left = 4;
    right = 3;
  } else if (left === 33 && right === 100) {
    left = 1;
    right = 3;
  } else if (left === 367 && right === 100) {
    left = 4;
    right = 1;
  } else if (left === 7 && right === 50) {
    left = 1;
    right = 7;
  } else if (left === 4 && right === 25) {
    left = 1;
    right = 6;
  } else if (left === 7 && right === 20) {
    left = 1;
    right = 3;
  } else if (left === 3 && right === 10) {
    left = 1;
    right = 3;
  }
  return [left, right];
};

// An way to declaratively fire a Google Analytics event.
// By memo-izing it we are able to minimize risk of it firing multiple times
// on re-renders.
export const GAEvent = memo(
  ({
    action,
    category,
    label,
  }: {
    action: string;
    category: string;
    label: string;
    }) => {
    let w: any;
    w = window;
    w.gtag("event", action, {
      event_category: category,
      event_label: label,
    });
    return null;
  }
);

// https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086
export const debounce = (func: Function, wait: number, immediate?: boolean) => {
  var timeout;

  // eslint-disable-next-line no-unused-vars
  return function executedFunction(this: any) {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const oddsToString = (odds: [number, number]): string => `${intcomma(odds[0])}/${intcomma(odds[1])}`;

// Take a payout, convert it to its corresponding odds, and then convert back to a payout
export const normalizePayout = (payout: number): number => {
  const odds = payoutToOdds(payout);
  return odds[0] / odds[1] + 1;
};

export const getBetCapacity = (
  action: number, existingTotal: number
): number => {
  let betCapacity = 0;
  betCapacity = (action * PCT_MAX - 100 * existingTotal)
    / (100 - PCT_MAX);
  if (betCapacity + existingTotal < MINIMUM_ALLOWED) {
    betCapacity = MINIMUM_ALLOWED - existingTotal;
  } else {
    betCapacity = Math.floor(betCapacity);
  }
  
  return Math.max(0, betCapacity);
}