// Third-party libraries
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Project dependencies
import { LoadingBar } from "../components/widgets";
import {
  getHeaders,
  errorHandler,
  propositionUrl,
  scratchUrl,
} from "../apihelpers.js";
import { Meta, NotStaff } from "../utilities";
import ScratchOption from "../components/ScratchOption";

class Scratch extends Component {
  state = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getProposition(slug) {
    const headers = getHeaders();
    fetch(propositionUrl + "/" + slug, {
      method: "GET",
      headers,
    })
      .then(errorHandler)
      .then((data) => {
        this.setState({ proposition: data });
      })
      .catch((error) => console.log(error));
  }

  componentWillMount() {
    const { slug } = this.props.match.params;
    this.getProposition(slug);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    // Scratches will get stored to the state based on their id, which is set in the input name property.
    // State will end up looking like: [{scratch-1: true}, {reason-1: 'Bad knee}]
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    const headers = getHeaders();
    this.setState({ loading: true });

    // Collect the scratches into an array that can be sent to back end.
    let scratches = [];
    let scratchIDs = Object.keys(this.state)
      .filter((key) => key.startsWith("scratch"))
      .map((key) => key.replace("scratch-", ""));

    scratchIDs.forEach((id) => {
      scratches.push({
        option_data_id: id,
        reason: this.state["reason-" + id] || "",
      });
    });
    // This will send an array of scratches in the form of [{scratch: option_data_id, reason: 'bad knee'}].
    // Expected response is an object of updated proposition data.
    fetch(scratchUrl, {
      method: "POST",
      headers,
      body: JSON.stringify(scratches),
    })
      .then(errorHandler)
      .then((data) => this.setState({ proposition: data, loading: false }))
      .catch((error) => console.log(error));

    event.preventDefault();
  }

  render() {
    if (this.props.player && !this.props.player.is_staff) {
      return (
        <Fragment>
          <Meta
            title="Non-staff visit to scratch"
            description="Scratching a rider."
          />
          <NotStaff />
        </Fragment>
      );
    }

    if (this.state.proposition) {
      return (
        <article>
          <Meta
            title={`Scratch: ${this.state.proposition.question}`}
            description="Scratching a rider."
          />
          {this.state.proposition.race && (
            <div className="proposition__label">
              {this.state.proposition.race.name}
            </div>
          )}

          <h1>{this.state.proposition.question}</h1>

          <h2>Active Riders</h2>
          <form onSubmit={this.handleSubmit}>
            {this.state.proposition.options
              .filter((option) => option.scratch === false)
              .map((option, i) => (
                <ScratchOption
                  key={i}
                  option={option}
                  handleChange={this.handleChange}
                  checked={this.state["scratch-" + option.id] || false}
                  reason={this.state["reason-" + option.id] || ""}
                />
              ))}
            <button
              className={`button--bet ${
                this.state.loading ? "button--hide" : ""
              }`}
            >
              Scratch riders
            </button>
          </form>

          <h2>Scratched Riders</h2>
          {this.state.proposition.options
            .filter((option) => option.scratch === true)
            .map((option, i) => (
              <ScratchOption key={i} option={option} />
            ))}
        </article>
      );
    } else {
      return <LoadingBar />;
    }
  }
}

Scratch.propTypes = {
  loading: PropTypes.bool,
};

export default Scratch;
