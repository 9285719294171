// Third-party libraries
import React from "react";
import { Link, NavLink } from "react-router-dom";
// import { intComma, ordinal } from "humanize-plus";
// import { TransitionGroup, CSSTransition } from "react-transition-group";

// Local dependencies
import ArchiveIcon from "../svg/ArchiveIcon";
// import LoginForm from "./LoginForm";
// import TimelineIcon from "../svg/TimelineIcon";
// import BookmarkIcon from "../svg/BookmarkIcon";
import EmailIcon from "../svg/EmailIcon";
import GavelIcon from "../svg/GavelIcon";
import HomeIcon from "../svg/HomeIcon";
// import LeagueIcon from "../svg/LeagueIcon";
// import LogoutIcon from "../svg/LogoutIcon";
// import { Logo } from "../svg/Logo";
// import NewspaperIcon from "../svg/NewspaperIcon";
import QuestionIcon from "../svg/QuestionIcon";
import TrophyIcon from "../svg/TrophyIcon";
// import SlackIcon from "../svg/SlackIcon";
import variables from "../styleVariables";

function Sidebar({
  // afterLogin,
  // loggedIn,
  // logout,
  // player,
  showMobileSidebar,
  // showSidebarLogin,
  // toggleSidebarLogin,
  path,
}) {
  return (
    <header className={showMobileSidebar ? "sidebar" : "sidebar sidebar--hide"}>
      {/* {loggedIn ? (
        <PlayerFacts player={player} />
      ) : (
        <SidebarLoginForm
          afterLogin={afterLogin}
          showSidebarLogin={showSidebarLogin}
          toggleSidebarLogin={toggleSidebarLogin}
        />
      )}
      {loggedIn && <PlayerNav player={player} logout={logout} path={path} />}

      <hr className="sidebar__divider" /> */}
      <nav>
        <ul className="sidebar__list">
          <li className="sidebar__item">
            <NavLink
              exact
              activeClassName="sidebar__link--active"
              to="/"
              className="sidebar__link"
            >
              <HomeIcon
                color={
                  path === "/" ? variables.colorPrimary : variables.colorBody
                }
              />
              Open for betting
            </NavLink>
          </li>
          <li className="sidebar__item">
            <NavLink
              exact
              activeClassName="sidebar__link--active"
              to="/rd/archive"
              className="sidebar__link"
            >
              <ArchiveIcon
                color={
                  path === "/rd/archive"
                    ? variables.colorPrimary
                    : variables.colorBody
                }
              />
              Scored Propositions
            </NavLink>
          </li>
          <li className="sidebar__item">
            <NavLink
              exact
              activeClassName="sidebar__link--active"
              to="/rd/leaders"
              className="sidebar__link"
            >
              <TrophyIcon
                color={
                  path === "/rd/leaders"
                    ? variables.colorPrimary
                    : variables.colorBody
                }
              />
              Leaders
            </NavLink>
          </li>
          <li className="sidebar__item">
            <NavLink
              exact
              activeClassName="sidebar__link--active"
              to="/rd/about"
              className="sidebar__link"
            >
              <QuestionIcon
                color={
                  path === "/rd/about"
                    ? variables.colorPrimary
                    : variables.colorBody
                }
              />
              How to play
            </NavLink>
          </li>
          <li className="sidebar__item">
            <NavLink
              exact
              activeClassName="sidebar__link--active"
              to="/rd/rules"
              className="sidebar__link"
            >
              <GavelIcon
                color={
                  path === "/rd/rules"
                    ? variables.colorPrimary
                    : variables.colorBody
                }
              />
              Rules
            </NavLink>
          </li>
          <li className="sidebar__item">
            <a className="sidebar__link" href="mailto:info@rouleurderby.com">
              <EmailIcon />
              Contact
            </a>
          </li>
        </ul>
      </nav>

      <nav className="terms">
        <ul className="terms__ul">
          <li>
            <Link to="/rd/terms">Terms of Service/</Link>
          </li>
          <li>
            <Link to="/rd/privacy">Privacy Policy</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

// function SidebarLoginForm({
//   afterLogin,
//   showSidebarLogin,
//   toggleSidebarLogin,
// }) {
//   if (showSidebarLogin) {
//     return (
//       <Fragment>
//         <div className="sidebar__login-form">
//           <LoginForm afterLogin={afterLogin} />
//         </div>
//       </Fragment>
//     );
//   } else {
//     return (
//       <p>
//         To play Rouleur Derby,{" "}
//         <button
//           className="button--inline"
//           type="button"
//           onClick={toggleSidebarLogin}
//         >
//           log in
//         </button>{" "}
//         or <Link to="/rd/new">create an account</Link>.
//       </p>
//     );
//   }
// }

// function PlayerNav({ player, logout, path }) {
//   return (
//     <nav>
//       <ul className="sidebar__list">
//         <li className="sidebar__item">
//           <NavLink
//             activeClassName="sidebar__link--active"
//             to={`/${player.handle}`}
//             className="sidebar__link"
//           >
//             <Logo
//               background={variables.colorPanel}
//               foreground={
//                 path === "/" + player.handle
//                   ? variables.colorPrimary
//                   : variables.colorBody
//               }
//               stroke={variables.colorPanel}
//             />
//             Your profile
//           </NavLink>
//         </li>
//         <li className="sidebar__item">
//           <NavLink
//             activeClassName="sidebar__link--active"
//             to="/bets"
//             className="sidebar__link"
//           >
//             <BookmarkIcon
//               color={
//                 path === "/bets" ? variables.colorPrimary : variables.colorBody
//               }
//             />
//             Your bets
//           </NavLink>
//         </li>
//         <li className="sidebar__item">
//           <NavLink
//             activeClassName="sidebar__link--active"
//             to="/transactions"
//             className="sidebar__link"
//           >
//             <TimelineIcon
//               color={
//                 path === "/transactions"
//                   ? variables.colorPrimary
//                   : variables.colorBody
//               }
//             />
//             Your history
//           </NavLink>
//         </li>
//         <li className="sidebar__item">
//           <NavLink
//             activeClassName="sidebar__link--active"
//             to="/leagues"
//             className="sidebar__link"
//           >
//             <LeagueIcon
//               color={
//                 path === "/leagues"
//                   ? variables.colorPrimary
//                   : variables.colorBody
//               }
//             />
//             Leagues
//           </NavLink>
//         </li>

//         <li className="sidebar__item sidebar__item--slack">
//           <a
//             href="https://rouleurderby.slack.com"
//             className="sidebar__link"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <SlackIcon className="sidebar__icon--slack" />
//             Chat on Slack
//           </a>
//         </li>
//         {!player.is_subscribed_to_report && (
//           <li className="sidebar__item">
//             <a
//               href="https://mailchi.mp/8a097990027d/subscribe"
//               className="sidebar__link"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <NewspaperIcon />
//               Newsletter
//             </a>
//           </li>
//         )}

//         <li className="sidebar__item">
//           <button
//             className="sidebar__link--button"
//             type="button"
//             onClick={logout}
//           >
//             <LogoutIcon />
//             Log out
//           </button>
//         </li>
//       </ul>
//     </nav>
//   );
// }

// function PlayerFacts({ player }) {
//   return (
//     <Fragment>
//       <TransitionGroup className="sidebar__points--transition">
//         <CSSTransition
//           key={player.points}
//           timeout={5000}
//           classNames="sidebar__points--fade"
//         >
//           <div
//             className={`sidebar__points ${
//               player.points > 9999 ? "sidebar__points--5-digits" : ""
//             }`}
//           >
//             <div className="sidebar__points-label">Your points</div>
//             <span className="sidebar__points-total">
//               {intComma(player.points)}
//             </span>
//             <div className="sidebar__points-meta">
//               <div className="sidebar__points-meta-item">
//                 {intComma(player.current_action)}{" "}
//                 <span className="sidebar__points-meta-label">in play</span>
//               </div>
//               <div className="sidebar__points-meta-item">
//                 {player.bet_tally > 0 ? (
//                   <Fragment>
//                     {ordinal(player.rank[0])}{" "}
//                     <span className="sidebar__points-meta-label">
//                       out of {intComma(player.rank[1])}
//                     </span>
//                   </Fragment>
//                 ) : (
//                   <Fragment>
//                     Not
//                     <br />
//                     ranked
//                   </Fragment>
//                 )}
//               </div>
//             </div>
//           </div>
//         </CSSTransition>
//       </TransitionGroup>
//     </Fragment>
//   );
// }

export default Sidebar;
