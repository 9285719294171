// Third-party libraries
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// Project dependencies
import { Logo } from "../svg/Logo";
import MenuIcon from "../svg/MenuIcon";

function Banner({ scrolled, showMobileSidebar, toggleShowMobileSidebar }) {
  let bannerClass = "banner";
  let h1Addendum = "";
  if (scrolled) {
    bannerClass += " banner--scrolled";
  }
  if (
    window.location.hostname === "localhost" &&
    navigator.userAgent !== "ReactSnap"
  ) {
    bannerClass += " banner--dev";
    h1Addendum += "DEV";
  }
  if (
    window.location.hostname === "dev.rouleurderby.com" &&
    navigator.userAgent !== "ReactSnap"
  ) {
    bannerClass += " banner--staging";
    h1Addendum += "TESTING";
  }
  if (window.location.pathname === "/") {
    bannerClass += " banner--homepage";
  }
  if (showMobileSidebar) {
    bannerClass += " banner--show-mobile-sidebar";
  }

  return (
    <header className={bannerClass}>
      <Link to="/">
        <div className="banner__logo">{!scrolled && <Logo />}</div>
      </Link>
      <h1 className="banner__h1">
        <Link to="/">Rouleur Derby</Link>
        {<span className="banner__h1--env"> {h1Addendum}</span>}

        <span className="banner__tagline">
          presented by{" "}
          <a
            href="https://www.wideanglepodium.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Wide Angle Podium
          </a>
        </span>
      </h1>

      <button
        className={
          showMobileSidebar
            ? "banner__menu-toggle banner__menu-toggle--hide button--inline"
            : "banner__menu-toggle banner__menu-toggle--show button--inline"
        }
        type="button"
        onClick={toggleShowMobileSidebar}
      >
        {showMobileSidebar ? (
          "X"
        ) : (
          <Fragment>
            <span>Menu</span>
            <MenuIcon />
          </Fragment>
        )}
      </button>
    </header>
  );
}

export default Banner;
