import React from "react";

function Footer() {
  return (
    <footer>
      Ⓒ {new Date().getFullYear()}{" "}
      <a href="https://www.climbermedia.com">Climber Media</a> LLC, all rights
      reserved!
    </footer>
  );
}

export default Footer;
