const variables = {
  mobileBreakpoint: "900px",
  colorBody: "#3a393a",
  colorError: "#dd0606",
  colorPanel: "#fafafa",
  colorErrorLight: "rgba(#dd0606, 0.8)",
  colorErrorPanel: "rgba(#dd0606, 0.1)",
  colorNeutral1: "#222",
  colorNeutral2: "#aaa",
  colorNeutral3: "#999",
  colorNeutral4: "#505050",
  colorPanelDark: "darken(#0074d9, 20%)",
  colorPrimary: "#0074d9",
  colorPrimaryLight: "lighten(#0074d9, 20%)",
  colorPrimaryLighter: "lighten(#0074d9, 50%)",
  colorPrimaryPanel: "lighten(#0074d9, 0.1)",
  colorRule: "##a5a5a5",
  colorSecondary: "#ee2323",
  colorSecondaryLight: "lighten(#ee2323, 20%)",
  colorSuccess: "#2b802b",
  colorSuccessLight: "rgba(#2b802b, 0.8)",
  colorSuccessLighter: "rgba(#2b802b, 0.5)",
  colorSuccessPanel: "rgba(#2b802b, 0.1)",
  colorWap: "#fd9d02",
  colorCancel: "#dd0606",
}

export default variables;