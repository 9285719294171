// Third-party libraries
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

// Project dependencies
import { getHeaders, passwordResetConfirmUrl } from "../apihelpers";
import LoginForm from "../components/LoginForm";
import { LoadingBar } from "../components/widgets";
import { Meta } from "../utilities";

class ResetPasswordConfirm extends Component {
  state = {
    new_password: "",
    submitted: false,
    passwordError: false,
    loading: false,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      new_password: event.target.value,
    });
  }

  handleSubmit(event) {
    this.setState({ submitted: true });
    const headers = getHeaders();
    fetch(passwordResetConfirmUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        new_password: this.state.new_password,
        uid: this.props.match.params.uid,
        token: this.props.match.params.token,
      }),
    })
      .then((response) => {
        if (response.status === 400) {
          return response.json();
        } else {
          this.setState({ loading: false, submitted: true });
        }
      })
      .then((errorResponse) => {
        this.setState({ loading: false });
        if (errorResponse.password) {
          this.setState({
            passwordError: errorResponse.password.join(" "),
          });
        }
      })
      .catch((error) => console.log(error));
    event.preventDefault();
  }

  render() {
    if (this.props.loggedIn) {
      return <Redirect to="/" />;
    } else if (this.state.submitted) {
      return (
        <Fragment>
          <Meta
            title="Reset Password Successful"
            description="Enter your email to get a link for resetting your password."
          />

          <h1>Your Password Has Been Reset</h1>
          <p>
            Please log in below to make sure. Report any problems to{" "}
            <a href="mailto:info@rouleurderby.com?subject=Password%20problem">
              info@rouleurderby.com
            </a>
            .
          </p>

          <LoginForm afterLogin={this.props.afterLogin} />
        </Fragment>
      );
    } else
      return (
        <Fragment>
          <Meta
            title="Reset Password Confirmation"
            description="Enter your email to get a link for resetting your password."
          />

          <h1>Set New Password</h1>

          {this.state.loading ? (
            <LoadingBar />
          ) : (
            <form onSubmit={this.handleSubmit}>
              <label htmlFor="new_password">New Password</label>
              <input
                id="new_password"
                onChange={this.handleChange}
                type="password"
                name="new_password"
                value={this.state.new_password}
              />
              <button className="button--clear">Change password</button>
            </form>
          )}
        </Fragment>
      );
  }
}

ResetPasswordConfirm.propTypes = {
  new_password: PropTypes.string,
  submitted: PropTypes.bool,
  loading: PropTypes.bool,
  passwordError: PropTypes.bool,
};

export default ResetPasswordConfirm;
