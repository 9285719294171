import React from "react";

function ScoreOption({ propositionType, option, handleChange, checked }) {
  return (
    <div className="option">
      <div className="option__main option__main--single-column">
        {propositionType === "winner" ? (
          <input
            type="radio"
            value={`score-${option.id}`}
            onChange={handleChange}
            name="winner"
            id={`score-${option.id}`}
          />
        ) : (
          <input
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            name={`score-${option.id}`}
            id={`score-${option.id}`}
          />
        )}
        <label htmlFor={`score-${option.id}`}>{option.option.name}</label>
      </div>
    </div>
  );
}

export default ScoreOption;
